<template>
  <div>
    <div class="about">
      <h1>Maintenance</h1>
      <div>
        The interface is in maintenance, please wait a few moment
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Maintenance'
}
</script>

<style scoped>
  .about {
    text-align: center;
    margin-top: 124px;
  }
</style>
